import React, {FC, ReactElement} from 'react';
import "bulma/css/bulma.css";
import {Hero} from "../Hero/Hero";
import {Services} from "../Services/Services";
import {Footer} from "../Footer/Footer";
import Contact from "../Contact/Contact";
import Reviews from "../Reviews/Reviews";
import "./../../Assets/Scss/App.css";


const App: FC = (): ReactElement => {
    return (
        <>
            <Hero/>

            <div className="container">
                <Services />
                <Reviews />
                <Contact />
            </div>

            <Footer />
        </>
    );
}

export default App;
