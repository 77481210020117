import React, {FC, ReactElement} from "react";

export const Hero: FC = (): ReactElement => {

    const scroll = (): void => {
        window.scrollTo(0, document.body.scrollHeight);
    }

    return (
        <>
            <section className="hero is-warning is-medium is-bold" style={{ marginTop: "-2.2em", marginBottom: "-3.5em"}}>
                <div className="hero-body" style={{padding: "3em"}}>
                    <div className="container has-text-centered">
                        <img src="https://i.postimg.cc/V69r12Vc/renmar.png" alt="Logo"/>
                    </div>
                </div>
            </section>

            <div className="box cta" style={{borderRadius: "0px"}}>
                <div className="has-text-centered">
                    <b>Please use the <a onClick={scroll}>Contact Form</a> at the bottom of the page to arrange a free
                        quote.</b>
                </div>
            </div>
        </>
    );
}
