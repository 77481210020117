import * as React from "react";
import {ReactElement, useState} from "react";
import emailjs from "emailjs-com";

const renderSuccess: ReactElement = (
    <div className="notification is-primary has-text-centered is-light">
        Thank you for your enquiry. We will get back to you shortly!
    </div>
);

const Contact = (): ReactElement => {
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccessful] = useState<boolean>(false);

    const handleSubmit = (event: any): void => {
            event.preventDefault();
            setLoading(true);

            emailjs
                .sendForm(
                    "service_jt9tlfx",
                    "template_reiyleb",
                    event.target,
                    "lQdZnUCs5p6XqKJXN"
                )
                .then(
                    () => setSuccessful(true),
                    () => {
                        setError("Opps, something has gone wrong");
                        setLoading(false);
                    }
                );
    };

    const renderForm: JSX.Element = (
        <form onSubmit={handleSubmit}>

            <div className="columns mb-2">
                <div className="column">
                    <div className="field">
                        <label htmlFor="name" className="label">
                            First Name
                        </label>
                        <div className="control">
                            <input
                                id="firstname"
                                className="input"
                                type="text"
                                placeholder="Last Name&hellip;"
                                name="firstname"
                                required
                                disabled={loading}
                            />
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
                        <label htmlFor="name" className="label">
                            Last Name
                        </label>
                        <div className="control">
                            <input
                                id="secondname"
                                className="input"
                                type="text"
                                placeholder="First Name&hellip;"
                                name="lastname"
                                required
                                disabled={loading}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <div className="field">
                        <label htmlFor="email" className="label">
                            Email
                        </label>
                        <div className="control">
                            <input
                                id="email"
                                className="input"
                                type="email"
                                placeholder="Email address&hellip;"
                                name="email"
                                required
                                disabled={loading}
                            />
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
                        <label htmlFor="phone" className="label">
                            Telephone
                        </label>
                        <div className="control">
                            <input
                                id="phone"
                                className="input"
                                type="tel"
                                placeholder="Telephone number&hellip;"
                                name="phone"
                                required
                                disabled={loading}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="field">
                <label htmlFor="message" className="label">
                    Message
                </label>
                <div className="control">
          <textarea
              id="message"
              className="textarea"
              placeholder="Enquiry&hellip;"
              name="message"
              required
              readOnly={loading}
          />
                </div>
            </div>
            {error === "" ? null : (
                <div className="notification is-danger is-light">{error}</div>
            )}
            <div className="field pt-5 has-text-centered">
                <button
                    className="button is-warning"
                    style={{paddingLeft: 40, paddingRight: 40}}
                >
                    {loading ? "Sending..." : "Send"}
                </button>
            </div>
        </form>
    );

    return (
        <>
            <div
                className="columns is-centered is-12-tablet has-text-centered page-header"
                style={{marginTop: 25, marginBottom: 25}}
            >
                <div className="column is-12" id="contact-us">
                    <h2 className="title">
                        Contact Us.
                    </h2>
                    <hr/>
                    <p>Please don't hesitate to get in contact with us in regards to any building needs or questions you may have. We aim to get back to all queries within 1 to 3 working days.</p>
                </div>
            </div>

            <div className="columns is-multiline mb-2">
                <div className="column is-10 is-offset-1">
                    {success ? renderSuccess : renderForm}
                </div>

            </div>
        </>
    );
};

export default Contact;
