import React, { ReactElement } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import "../../Assets/scss/reviews.scss"

const Reviews = (): ReactElement => {
    return (
        <div className="testimonials mt-5 mb-5">
            <h2 className="title has-text-centered title">
                Reviews.
            </h2>

            <hr />

            <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                showIndicators={false}
                interval={4100}
            >
                <div>
                    <div className="myCarousel">
                        <div className="columns">
                            <div className="column">
                                <div className="is-italic">
                                    "Excellent service from Renmar construction ltd - very pleased. Sonnie and Lloyd fitted us a brand new kitchen which looks amazing. They finished on schedule and on budget, the pair went the extra mile to create or dream kitchen. I'd 100% recommend Renmar to anyone looking for a reliable and professional company, they'll be the first company I call for any work."
                                </div>
                                <div className="author-text mt-5">
                                    <p>Paul, Nottingham</p>
                                    <span>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="myCarousel">
                        <div className="columns">
                            <div className="column">
                                <div className="is-italic">
                                    "After getting various quotes for wall panelling in our bedroom and stairs we decided to go with Renmar construction. Top class service from start to finish, Sonnie came to price the job up on Thursday and started the work the next day. Throughout all stages of the project they kept us in the loop. We're having Renmar back next month to fit some new doors."
                                </div>
                                <div className="author-text mt-5">
                                    <p>Victoria, Sutton-In-Ashfield</p>
                                    <span>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="myCarousel">
                        <div className="columns">
                            <div className="column">
                                <div className="is-italic">
                                    "Renmar are very professional. They always kept us informed on every stage of the renovation and kept the build within our pre-agreed budget. I'm so glad we decided to give them a call"
                                </div>
                                <div className="author-text mt-5">
                                    <p>James, Sheffield</p>
                                    <span>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="myCarousel">
                        <div className="columns">
                            <div className="column">
                                <div className="is-italic">
                                    "Renmar Construction have offered an impeccable service from start to finish. They offered a fair and competitive quote, communicated well throughout and produced quality work. So happy with my new doors!"
                                </div>
                                <div className="author-text mt-5">
                                    <p>Cathrine, Ripley</p>
                                    <span>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>

            <hr />
        </div>
    );
};

export default Reviews;
