import React, {FC, ReactElement} from "react";

export const Services: FC = (): ReactElement => {

    return (
        <div className="mb-5">
            <div className="column is-12 has-text-centered">
            </div>
            <div className="columns features">
                <div className="column is-4">
                    <div className="card is-shady" style={{backgroundColor: "#ececec"}}>
                        <div className="card-image has-text-centered">
                            <img src="https://i.postimg.cc/Fz3MML30/construction.png" alt="something"/>
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <h4>Extensions. </h4>
                                <p>Our team of highly rated builders undertake extensions of all shapes and sizes from the ground to completion with quality always being at the forefront.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="card is-shady" style={{backgroundColor: "#ececec"}}>
                        <div className="card-image has-text-centered">
                            <img src="https://i.postimg.cc/nLdp2BGz/kitchen.png" alt="something"/>
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <h4>Kitchens. </h4>
                                <p>Our professional installers will take the stress out of bringing your new kitchen to life. Here we go above and beyond to ensure that the kitchen is completed to the highest of standards.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="card is-shady" style={{backgroundColor: "#ececec"}}>
                        <div className="card-image has-text-centered">
                            <img src="https://i.postimg.cc/prh7Dnqg/media-wall.png" alt="media wall"/>
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <h4>Joinery.</h4>
                                <p>Our team of trusted joiners take on all aspects of the job from bespoke made to measure media walls and panelling, to first fix, second fix and traditional truss roofs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
